import { createTheme } from '@mui/material/styles';
import { deDE as coreDeDE } from '@mui/material/locale';

import { HeaderLayout, ThemeCustomizing } from '../types/types';
import { alpha, lighten } from '@material-ui/core';

declare module '@mui/material/styles' {
  interface Palette {
    footer: Palette['primary'],
    header: Palette['primary'],
  }
  interface PaletteOptions {
    footer: PaletteOptions['primary'],
    header: PaletteOptions['primary'];
  }
}

export const selectTheme = function (customizingid: string | undefined | null): ThemeCustomizing {

  /**
   * Default customizings hinterlegen. Dies kann perspektivisch auch in einen Service-Call verlagert werden, 
   * damit dynamisch Themes geladen werden können.
   * 
   */
  if ("alb" === customizingid || "albrd" === customizingid) {
    const albCustomThemeData = {
      headerLayout: HeaderLayout.FIXED_FULL_WIDTH,
      font: {
        fontSize: 16,
        fontWeightBold: 700,
        fontWeightRegular: 400,
        fontWeightLight: 100,
      },
      colors: {
        font: 'rgb(5, 22, 77)',
        background: "#FFFFFF",
        primary: "#05164D",
        primaryContrast: "#ffffff",
        secondary: "#FFFFFF",
        secondaryContrast: "#05164D",
        headerBackgroundColor: "#F6F9FC",
        footerBackgroundColor: "#F6F9FC",
        footerPrimaryColor: "#05164D",
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: '#52627c',
              color: '#ffffff',
              border: '1px solid #52627c',
              "@media (hover: none)": {
                backgroundColor: '#52627c',
                color: '#ffffff',
              },
            },
            textTransform: 'none',
            lineHeight: 'initial',
            primary: '#05164D',
            '&.la': {
              background: '#05164D',
              color: '#ffffff',
              padding: '0 30px',
              '&:hover': {
                backgroundColor: '#52627c',
                color: '#ffffff',
                border: '1px solid #52627c',
                "@media (hover: none)": {
                  backgroundColor: '#52627c',
                  color: '#ffffff',
                },
              },
            },
          },
          text: {
            '&.MuiButton-textPrimary': {
              background: '#05164D',
              color: '#ffffff',
            },
            '&.MuiButton-textPrimary:hover': {
              backgroundColor: '#52627c',
              color: '#ffffff',
              border: '1px solid #52627c'
            },
            '&.MuiButton-textPrimary.Mui-disabled': {
              color: alpha('#ffffff', 0.5),
              background: lighten(alpha('#009767', 0.5), 0.5),
            },
            // Some CSS
            //background: 'linear-gradient(45deg, #0069b4 30%, #009767 90%)',
            background: '#05164D',
            borderRadius: '4px',
            border: '1px solid #05164D',
            color: '#ffffff',
            fontSize: '0.9rem',
            fontWeight: 600,
            primary: '#05164D',
            height: 48,
            padding: '0 30px',
            boxShadow: 'none',
          },
          endIcon: {
            display: 'none',
            visibility: 'hidden'
          },
        }
      },
      title: 'Albatros LinkedApp',
      logoSrc: process.env.REACT_APP_LINKEDAPP_BACKEND_ROOT + "/static/media/albatros_rhion_coop_logo.svg",
      impressumSrc: 'https://www.albatros.de/page/impressum',
      agbSrc: undefined,
      datenschutzSrc: 'https://www.albatros.de/page/datenschutz',
      homepage: 'https://www.albatros.de',
      homepageTitle: 'Albatros',
      copyright: 'RheinLand Versicherungs AG',
      serviceTel: '02131 2010 7140',
      trustElement: "/static/media/albatros_trust.svg"
    };
    if(customizingid === "albrd") {
      return {
        ...albCustomThemeData,
        logoSrc: process.env.REACT_APP_LINKEDAPP_BACKEND_ROOT + "/static/media/rhion-logo.svg",
        impressumSrc: 'https://www.rhion.digital/impressum/',
        datenschutzSrc: 'https://rhion.digital/fileadmin/redaktion/DOWNLOADS/240528_Datenschutzerklaerung_Rhion_App_V.1.3.pdf',
        agbSrc: undefined
      };
    }
    else {
      return albCustomThemeData;
    }
  }
  else if ("ahd" === customizingid) {
    // Autohaus-Direkt ("mobivers")
    return {
      headerLayout: HeaderLayout.FIXED_FULL_WIDTH,
      colors: {
        background: "#fafafa",
        primary: "#0463C2",
        primaryContrast: "#ffffff",
        secondary: "#D63541",
        secondaryContrast: "#ffffff",
        headerBackgroundColor: "#fafafa",
        footerBackgroundColor: "#0463C2",
        footerPrimaryColor: "#fafafa",
      },
      title: 'MOBIVERS LinkedApp',
      logoSrc: process.env.REACT_APP_LINKEDAPP_BACKEND_ROOT + "/static/media/mobivers-logo-rot.svg",
      impressumSrc: 'https://www.mobivers.de/impressum.html',
      agbSrc: 'https://www.mobivers.de/nutzungsbedingungen.html',
      datenschutzSrc: 'https://www.mobivers.de/datenschutz.html',
      homepage: 'https://www.mobivers.de',
      homepageTitle: 'Mobivers',
      copyright: 'RheinLand Versicherungs AG',
      serviceTel: '02131 2010 7140'
    }
  } else if ("cl" === customizingid) {
    // credit life theme
    return {
      headerLayout: HeaderLayout.FIXED_FULL_WIDTH,
      colors: {
        background: "#ffffff",
        primary: "#4d5357",
        primaryContrast: "#ffffff",
        secondary: "#bad60a",
        secondaryContrast: "#4d5357",
        headerBackgroundColor: "#ffffff",
        headerPrimaryColor: "#4d5357",
        footerBackgroundColor: "#4d5357",
        footerPrimaryColor: "#fafafa",
        answerHeadingBackgroundColor: "#bad60a",
        decentBackground: 'linear-gradient(170deg, rgb(77 83 87 / 5%) 0%, rgba(255, 255, 255, 0) 100%)',
        decentBackgroundEven: 'linear-gradient(170deg, rgb(77 83 87 / 5%) 0%, rgba(255, 255, 255, 0) 100%)',
        decentBackgroundOdd: 'linear-gradient(170deg, rgb(77 83 87 / 5%) 0%, rgba(255, 255, 255, 0) 100%)',
      },
      elements: {
        // Header der GUI-Elemente
        heading: {
          styleOverrides: {
            fontSize: '1.4rem',
            fontFamily: '"Frutiger LT W04_67 Bold Cond"',
            color: '#bad60a',
          }
        },
        // HTML-Block nach dem eigentlichen GUI-Element
        htmlAfter: {},
        // HTML-Block vor dem eigentlichen GUI-Element
        informationtext: {},
        // Allgemeine Style-Overrides der GUI-Elemente selbst
        styleOverrides: {
          a: {
            '&:hover': {
              color: '#000000'
            },
            color: '#bad60a'
          },
          border: '0px',
          borderRadius: '3rem',
          padding: '3rem',
          margin: '0 0 1rem 0',
        },
        backgroundEven: '#f2f2f2',
        backgroundOdd: '#f2f2f2',
      },
      buttonTextTransform: 'none',
      font: {
        fontFamily: '"FrutigerLTW02-45Light",sans-serif',
        fontSize: 15,
        headlineFontFamily: '"Frutiger LT W04_66 Bold Italic", sans-serif',
        styleOverrides: {
          h1: {
            fontFamily: '"Frutiger LT W04_66 Bold Italic", sans-serif',
            '&::after': {
              fontFamily: '"sans-serif"',
              fontSize: '2.4rem',
              content: '"."',
              color: '#bad60a'
            }
          },
          h2: {
            fontFamily: '"Frutiger LT W04_67 Bold Cond"',
            fontSize: '1.2rem',
            fontWeight: 600
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          text: {
            textTransform: 'none',
            lineHeight: '2.5rem',
            borderRadius: '2rem'
          },
        }
      },
      baseline: {
        styleOverrides: {
          ".MuiButton-root .MuiSvgIcon-root": {
            verticalAlign: 'middle'
          },
          ".MuiFormControl-root": {
            ".MuiInputBase-root": {
              "&.Mui-focused": {
                backgroundColor: '#ffffff'
              },
              "&:hover": {
                backgroundColor: '#ffffff'
              },
              backgroundColor: '#ffffff'
            }
          }
        }
      },
      title: 'CreditLife LinkedApp',
      logoSrc: process.env.REACT_APP_LINKEDAPP_BACKEND_ROOT + "/static/media/creditlife-logo.svg",
      homepage: 'https://www.creditlife.net',
      impressumSrc: 'https://www.creditlife.net/de/de/impressum.html',
      agbSrc: 'https://www.creditlife.net/de/de/nutzungsbedingungen.html',
      datenschutzSrc: 'https://www.creditlife.net/de/de/datenschutz.html',
      copyright: 'CreditLife AG',
      homepageTitle: 'CreditLife AG',
      serviceTel: '02131 2010 7000'
    }
  } else if ("rd" === customizingid) {
    // rhion digital theme
    return {
      headerLayout: HeaderLayout.FIXED_FULL_WIDTH,
      colors: {
        background: "linear-gradient(170deg, rgb(188 227 240) 0%, rgb(165 222 206) 100%)",
        backgroundColor: "#cfecf6",
        primary: "#18a075",
        primaryContrast: "#ffffff",
        info: "#ffffff",
        infoLight: '#ffffffba',
        infoContrast: "#18a075",
        secondary: "#0099cc",
        secondaryContrast: "#ffffff",
        headerBackgroundColor: "#ffffff80",
        headerPrimaryColor: '#181a1d',
        trustElementBackgroundColor: '#000000',
        footerBackgroundColor: "#000000",
        footerPrimaryColor: "#fafafa",
      },
      font: {
        fontFamily: '"KlavikaWebBasic",sans-serif',
        headlineFontFamily: '"KlavikaWebBasic",sans-serif',
        fontSize: 18,
      },
      title: 'rhion.digital LinkedApp',
      logoSrc: process.env.REACT_APP_LINKEDAPP_BACKEND_ROOT + "/static/media/rhion-logo.svg",
      impressumSrc: 'https://www.rhion.digital/impressum/',
      agbSrc: 'https://rhion.digital/fileadmin/redaktion/DOWNLOADS/Digitales_Postfach/20240626_Nutzungsbedingungen_Digitales_Postfach.pdf',
      datenschutzSrc: 'https://rhion.digital/fileadmin/redaktion/DOWNLOADS/Digitales_Postfach/20231207_Datenschutz_Digitales_Postfach.pdf',
      copyright: 'Rhion Versicherung AG',
      // kontaktdatenAendern: 'https://meinedaten.rhion.digital/#ihreDaten',
      faq: 'https://rhion.digital/kontakt/digitale-post/faq-digitales-postfach',
      homepage: 'https://www.rhion.digital',
      homepageTitle: 'rhion.digital',
      serviceTel: '02131 6099 0'
    }
  } else if ("void" === customizingid) {
    // empty theme (Loading short link)
    return {
      headerLayout: HeaderLayout.FIXED_FULL_WIDTH,
      colors: {
        background: "#f2f2f2",
        primary: "#f2f2f2",
        primaryContrast: "#666666",
        secondary: "#f2f2f2",
        secondaryContrast: "#666666",
        headerBackgroundColor: "#f2f2f2",
        footerBackgroundColor: "#f2f2f2",
        footerPrimaryColor: "#f2f2f2",
      },
      title: 'LinkedApp',
      logoSrc: null,
      impressumSrc: 'https://www.rheinland-versicherungen.de/impressum/',
      agbSrc: 'https://www.rheinland-versicherungen.de/nutzungsbedingungen/',
      datenschutzSrc: 'https://www.rheinland-versicherungen.de/datenschutz/',
      homepage: 'https://www.rheinland-versicherungen.de',
      copyright: 'RheinLand Versicherungs AG',
      homepageTitle: 'RheinLand Versicherungen',
      serviceTel: '02131 290 0'
    }
  } else {
    // default theme (RheinLand)
    return {
      headerLayout: HeaderLayout.FIXED_FULL_WIDTH,
      colors: {
        background: "#cce4f0",
        font: "#0063ba",
        primary: "#0063ba",
        primaryContrast: "#ffffff",
        secondary: "#009767",
        secondaryContrast: "#ffffff",
        info: "#ffffff",
        infoLight: '#ffffffba',
        infoContrast: "#0063ba",
        headerBackgroundColor: "#ffffff",
        headerPrimaryColor: '#0063ba',
        footerBackgroundColor: "#a4cade",
        footerPrimaryColor: "#fafafa",
        trustElementBackgroundColor: '#a4cade',
      },
      font: {
        fontFamily: '"Raleway",sans-serif',
        headlineFontFamily: '"Raleway",sans-serif',
      },
      title: 'RheinLand LinkedApp',
      logoSrc: process.env.REACT_APP_LINKEDAPP_BACKEND_ROOT + "/static/media/rheinland.svg",
      impressumSrc: 'https://www.rheinland-versicherungen.de/impressum/',
      agbSrc: 'https://www.rheinland-versicherungen.de/fileadmin/Dateiliste/PDFs/Digitales_Postfach/Nutzungsbedingungen_digitales_Postfach_RL.pdf',
      datenschutzSrc: 'https://www.rheinland-versicherungen.de/fileadmin/Dateiliste/PDFs/Digitales_Postfach/20230926_Digitales_Postfach_Datenschutzhinweise_RL.pdf',
      copyright: 'RheinLand Versicherungs AG',
      homepage: 'https://www.rheinland-versicherungen.de',
      homepageTitle: 'RheinLand Versicherungen',
      // kontaktdatenAendern: 'https://meinedaten.rheinland-versicherungen.de/#ihreDaten',
      faq: 'https://www.rheinland-versicherungen.de/index.php?id=2949',
      serviceTel: '02131 290 0',


      MuiButton: {
        styleOverrides: {
          text: {
            boxShadow: '0 3px 5px 2px rgba(100, 100, 100, .3)',
            //Back Button
            '&.button-wizard-back:hover': {
              backgroundColor: '#0063ba',
              color: '#cce4f0',
              border: '3px solid #0063ba'
            },
            '&.button-wizard-back': {
              // Some CSS
              //background: 'linear-gradient(45deg, #0069b4 30%, #009767 90%)',
              background: '#cce4f0',
              borderRadius: '0px',
              border: '3px solid #009767',
              fontSize: '1.0rem',
              fontWeight: 300,
              primary: '#0063ba',
              height: 48,
              padding: '0 30px',
              boxShadow: 'none',
            },
            '&.MuiButton-textPrimary.Mui-disabled': {
              color: alpha('#ffffff', 0.5),
              background: lighten(alpha('#009767', 0.5), 0.5),
              border: 'none', // Kein Rahmen
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            },
            '&.button-inline': {
              fontWeight:100,
              boxShadow: 'none',
              fontSize: '15px',
              background: 'none',
              padding:'16px',
              paddingTop:'10px',
              paddingBottom: '10px',
              height:'38px',
              textTransform: 'none',

            },
            // Some CSS
            //background: 'linear-gradient(45deg, #0069b4 30%, #009767 90%)',
            background: '#009767',
            borderRadius: '0px',
            //border: '1px solid #009767',
            color: '#ffffff',
            fontSize: '1.0rem',
            fontWeight: 300,
            primary: '#009767',
            height: 48,
            padding: '0 30px',
            minWidth: '64px',

            border: 'none', // Kein Rahmen

          },
        }
      },

    }
  }
}


export const BASIC_THEME = function (customizing: ThemeCustomizing, locale: Locale): any {
  return createTheme({
    typography: {
      body1: {
        fontSize: customizing?.font?.fontSize || 15,
      },
      htmlFontSize: customizing?.font?.fontSize || 15,
      h1: {
        fontSize: '2.0rem',
        fontWeight: customizing?.font?.fontWeightBold || 700,
        marginTop: '1.2rem',
        marginBottom: '1.2rem',
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: customizing?.font?.fontWeightBold || 700,
      },
      fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
      fontWeightMedium: customizing?.font?.fontWeightMedium || 300,
      fontWeightLight: customizing?.font?.fontWeightLight || 100,
      fontWeightBold: customizing?.font?.fontWeightBold || 700,
      fontWeightRegular: customizing?.font?.fontWeightRegular || 300,
      fontSize: customizing?.font?.fontSize || 15,
      ...customizing?.font?.styleOverrides ?? {}
    },
    palette: {
      primary: {
        main: customizing?.colors?.primary || '#009767',
        //light: '#4444',
        contrastText: customizing?.colors?.primaryContrast || '#4444',
      },
      secondary: {
        main: customizing?.colors?.secondary || '#0069b4',
        //light: '#4444',
        contrastText: customizing?.colors?.secondaryContrast || '#4444',
      },
      info: {
        main: customizing?.colors?.info || '#ffffff',
        light: customizing?.colors?.infoLight || '#ffffff',
        contrastText: customizing?.colors?.infoContrast || '#0063ba',
      },
      footer: {
        main: customizing?.colors?.footerBackgroundColor || '#0069b4',
        //light: '#4444',
        contrastText: customizing?.colors?.footerPrimaryColor || '#4444',
      },
      header: {
        main: customizing?.colors?.headerBackgroundColor || '#0069b4',
        //light: '#4444',
        contrastText: customizing?.colors?.headerPrimaryColor || '#4444',
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            '&.la': {
              fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            }
          }
        },
        defaultProps: {
          className: 'la'
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            zIndex: 1,
            fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            '&.la': {
              fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            }
          }
        },
        defaultProps: {
          className: 'la'
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '&.la': {
              fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            }
          }
        },
        defaultProps: {
          variant: 'filled',
          className: 'la'
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
          }
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: 'inherit',
            letterSpacing: 'initial',
            background: '#ffffff',
            backgroundColor: '#ffffff3b',
            "& input": {
              height: '1.4375em',
              paddingTop: 25,
              paddingRight: 12,
              paddingBottom: 8,
              paddingLeft: 12,
            },
          },
          underline: {
            "&::before": {
              borderBottom: '1px solid',
              borderBottomColor: customizing?.colors?.secondaryContrast || '#ffffff',
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: '2px solid',
              borderBottomColor: customizing?.colors?.secondary || '#ffffff',
            },
            "&::after": {
              borderBottom: '2px solid',
              borderBottomColor: customizing?.colors?.secondary || '#ffffff',
            }
          },
          input: {
          }
        },
        defaultProps: {
          className: 'la'
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: customizing?.colors?.primaryContrast || '#ffffff',
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            fontSize: customizing?.font?.fontSize || 15,
            letterSpacing: 'initial',
            background: '#ffffff',
            backgroundColor: '#ffffff3b',
            '&.la': {
              fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            }
          },
          input: {
          }
        },
        defaultProps: {
          className: 'la'
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            background: '#ffffff',
            backgroundColor: '#ffffff3b',
            paddingLeft: 4,
            paddingTop: 4,
          },
          underline: {
            "&::before": {
              borderBottom: '1px solid',
              borderBottomColor: customizing?.colors?.secondaryContrast || '#ffffff',
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: '2px solid',
              borderBottomColor: customizing?.colors?.secondary || '#ffffff',
            },
            "&::after": {
              borderBottom: '2px solid',
              borderBottomColor: customizing?.colors?.secondary || '#ffffff',
            }
          }
        },
        defaultProps: {
          className: 'la'
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:focus": {
              backgroundColor: 'inherit'
            }
          }
        },
        defaultProps: {
          className: 'la'
        },

      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: customizing?.colors?.primary || '#0069b4',
              color: customizing?.colors?.primaryContrast || '#ffffff',
              "@media (hover: none)": {
                backgroundColor: customizing?.colors?.primary || '#0069b4',
                color: customizing?.colors?.primaryContrast || '#ffffff',
              },
            },
            '&.button-inline' :{
              color: customizing?.colors?.primary,
              border:'1px solid',
              
            },
            textTransform: customizing?.buttonTextTransform !== undefined ? customizing?.buttonTextTransform : 'uppercase',
            lineHeight: 'initial',
            primary: customizing?.colors?.secondaryContrast || '#ffffff',
            '&.la': {
              background: customizing?.colors?.secondary || '#009767',
              color: customizing?.colors?.secondaryContrast || '#ffffff',
              padding: '0 30px',
              '&:hover': {
                backgroundColor: customizing?.colors?.primary || '#0069b4',
                color: customizing?.colors?.primaryContrast || '#ffffff',
                "@media (hover: none)": {
                  backgroundColor: customizing?.colors?.primary || '#0069b4',
                  color: customizing?.colors?.primaryContrast || '#ffffff',
                },
              },
            }
          },
          text: {
            '&.MuiButton-textPrimary': {
              background: customizing?.colors?.secondary || '#009767',
              color: customizing?.colors?.secondaryContrast || '#ffffff',
            },
            '&.MuiButton-textPrimary.button-wizard-back': {
              background: customizing?.colors?.background || '#009767',
              color: customizing?.colors?.secondaryContrast || '#ffffff',
            },
            '&.MuiButton-textPrimary:hover': {
              backgroundColor: customizing?.colors?.primary || '#0069b4',
              color: customizing?.colors?.primaryContrast || '#ffffff',
            },
            '&.MuiButton-textPrimary.Mui-disabled': {
              color: alpha(customizing?.colors?.secondaryContrast || '#ffffff', 0.5),
              background: lighten(alpha(customizing?.colors?.secondary || '#009767', 0.5), 0.5),
            },
            // Some CSS
            //background: 'linear-gradient(45deg, #0069b4 30%, #009767 90%)',
            background: customizing?.colors?.secondary || '#009767',
            borderRadius: '0',
            border: 0,
            color: customizing?.colors?.secondaryContrast || '#ffffff',
            primary: customizing?.colors?.secondaryContrast || '#ffffff',
            height: 48,
            padding: '0 30px',

          },
          ...customizing?.MuiButton?.styleOverrides ?? {}
        },
        defaultProps: {
          className: 'la'
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontWeight: "300",
            lineHeight: "1.5",
            color: "black",
            backgroundColor: "#ffffff",
            boxShadow: "rgba(0, 0, 0, 0.15) 2px 3px 3px",
            position: "absolute",
            top: "-15px",
            right: "-7px",
            minWidth: "450px",
            borderTopRightRadius: "0",

          },
          arrow: {
            "&:before": {
              //border: "1px solid #E6E8ED"

            },
            //width: "24px",
            //height: "17px",
            color: "#ffffff",
            //color: "red",
            marginLeft: "218px",
            top: "40px",

          },


        },
      },


      MuiCssBaseline: {
        styleOverrides: {
          html: {
            color: customizing?.colors?.font || '#181a1d',
            fontSize: '13pt',
            fontWeight: '300',
            fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            backgroundColor: customizing?.colors?.backgroundColor || '#fafafa',
            background: customizing?.colors?.background || '#fafafa',
          },
          body: {
            color: customizing?.colors?.font || '#181a1d',
            fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            backgroundColor: customizing?.colors?.backgroundColor || '#fafafa',
            background: customizing?.colors?.background || '#fafafa',
          },
          h1: {
            fontFamily: customizing?.font?.headlineFontFamily || 'Roboto, Helvetica, Arial, sans-serif',
          },
          ...customizing?.font?.styleOverrides ?? {},
          // Fix für Unschönheit im Mui-Datepicker. Kann evtl. mit neuer MUI-Version raus.
          ".MuiPickersDatePickerRoot-toolbar .MuiPickersToolbarButton-toolbarBtn": {
            backgroundColor: 'inherit',
            boxShadow: 'none'
          },
          // Fixes für die gemischte Verwendung des DatePickers mit alter JSS-Engine und Emotion aus MaterialUI 5.
          // Diese Hacks können verschwinden, sobald der offizielle DatePicker in V5 integriert wurde.
          ".MuiFormControl-root": {
            ".MuiFormLabel-root, .MuiInputLabel-root": {
              fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
              pointerEvents: 'none',
            },
            "&.datepicker .MuiFormLabel-root.MuiInputLabel-shrink": {
              transform: 'translate(12px, 26px) scale(0.75)'
            },
            "&.datepicker .MuiInputLabel-formControl": {
              zIndex: 1,
              transform: 'translate(12px, 36px) scale(1)',
              pointerEvents: 'none',
            },
            "&.datepicker .MuiInputBase-root.MuiInput-root.MuiInput-underline:hover": {
              background: 'rgba(0, 0, 0, 0.09)'
            },
            "&.datepicker .MuiInputBase-root.MuiInput-root": {
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px'
            },
            ".MuiInputBase-root": {
              fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
              background: '#ffffff',
              backgroundColor: '#ffffff3b',
            },
            ".MuiInputBase-root input": {
              fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
              paddingTop: 25,
              paddingRight: 12,
              paddingBottom: 8,
              paddingLeft: 12,
            },
            ".MuiInputBase-root.select": {
              lineHeight: '2.385rem',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px'
            },
            "&.select .MuiSelect-select.MuiInput-input.MuiInputBase-input": {
              paddingLeft: '8px',
              paddingTop: '14px'
            },
            "&.select label.MuiInputLabel-root": {
              lineHeight: '2.385rem',
              transform: 'translate(12px, 20px) scale(1)'
            },
            "&.select label.MuiInputLabel-root.MuiInputLabel-shrink": {
              transform: 'translate(12px, 14px) scale(0.75)',
            },
            ".MuiFormHelperText-root": {
              color: '#00FF00',
              fontFamily: customizing?.font?.fontFamily || 'Roboto, Helvetica, Arial, sans-serif',
            },
            ".MuiInput-underline": {
              "&::before": {
                borderBottom: '1px solid',
                borderBottomColor: customizing?.colors?.secondaryContrast || '#ffffff',
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottom: '2px solid',
                borderBottomColor: customizing?.colors?.secondary || '#ffffff',
              },
              "&::after": {
                borderBottom: '2px solid',
                borderBottomColor: customizing?.colors?.secondary || '#ffffff',
              }
            },
          },
          ".MuiPickersToolbar-toolbar": {
            backgroundColor: customizing?.colors?.backgroundColor || '#fafafa',
            background: customizing?.colors?.background || '#fafafa',
          },
          '.MuiStepper-root': {
            backgroundColor: 'transparent !important'
          },
          '.MuiStepContent-root': {
            color: customizing?.colors?.primary || '#ffffff'
          },
          '.MuiStepLabel-label': {
            '&.MuiStepLabel-completed': {
              color: `${customizing?.colors?.primary} !important`
            },
            '&.MuiStepLabel-active': {
              color: `${customizing?.colors?.primary} !important`
            },
          },
          '.MuiStepIcon-root': {
            '&.MuiStepIcon-completed': {
              color: `${customizing?.colors?.primary} !important`
            },
            '&.MuiStepIcon-active': {
              color: `${customizing?.colors?.primary} !important`
            },
          },
          '.answer-element-heading-info': {
            fontSize: '1.1rem',
            fontWeight: '600'
          },

          '&.textlinkInsteadOfButton': {
            textDecoration: 'underline',
            cursor: 'pointer',
            color: `${customizing?.colors?.primary} !important`
          },
          ...customizing?.baseline?.styleOverrides ?? {},
        },
      }
    },
  }, coreDeDE);
}
