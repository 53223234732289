import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { AnswerWizardState, AppState } from '../../../../types/types';
// import { useMediaQuery } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import HighlightFrame from './HighlightFrame';
import { AnswerElement } from "../../../../types/apimodel";
import AnswerElementGui from '../elements/AnswerElementGui';
import { isElementIfPartOfWizardPage } from "./WizardHelper";
import { Button, Grid } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import useAnswerCtx from '../useAnswerCtx';
import { log } from "../../../../util/util";
import { validateVisibleElements } from "../DependencyVisibilityHelper";
import { UNMOUNT_WIZARD_STEP, WIZARD_NEXT_STEP, WIZARD_PREVIOUS_STEP } from "../../../../pages/answerLink/AnswerReducer";
import { ReactNode } from 'react';
import { Hidden } from '@material-ui/core';
import zurueck from '../../../../assets/images/zurueck.svg';



interface Props {
  appState: AppState,
  wizardState: AnswerWizardState,
  answerComplete?: boolean,
  setPathToPage?: (nextStep: number) => void,
  getSaveButton?: () => ReactNode,
  confirmationPage?: ReactNode
}

const WizardSteps: React.FC<Props> = ({ wizardState, answerComplete, appState, setPathToPage, getSaveButton, confirmationPage }) => {
  // const theme = useTheme();
  const isSmallScreen = false; // useMediaQuery(theme.breakpoints.down("md"));
  const { state, dispatch } = useAnswerCtx();
  let disabledFirstStep = state.wizardState.disabledFirstStep;
  
  const navigate = (nextStep: number, resetValidation?: boolean) => {
    if (nextStep <= state.wizardState.numberOfSteps && nextStep >= 0) {
      dispatch({ type: UNMOUNT_WIZARD_STEP });
      // Verzögertes Aushängen der Komponente
      setTimeout(
        () => {
          log('Navigate to: ', nextStep)
          setPathToPage && setPathToPage(nextStep);
          if (state.wizardState?.currentStep > nextStep) {
            dispatch({ type: WIZARD_PREVIOUS_STEP, value: nextStep, resetValidation: resetValidation ?? false });
          } else {
            dispatch({ type: WIZARD_NEXT_STEP, value: nextStep, resetValidation: resetValidation ?? false });
          }
        }, 300
      );
    }
  }
  const jumpToWizardStep = function (nextStep: number) {
    log("jumpToNextWizardStep: " + nextStep);
    if (nextStep > wizardState?.currentStep) {
      validateVisibleElements(state.validationFunc).then((validationResult) => {
        if (validationResult.valid === true) {
          navigate(nextStep, true);
        } else {
          navigate(state?.wizardState?.currentStep);
        }
      }
      );
    } else {
      navigate(nextStep, true);
    }
  }

  
  return <>
    <Stepper orientation={isSmallScreen ? 'vertical' : 'horizontal'} activeStep={answerComplete ? (wizardState?.numberOfSteps) : (wizardState?.currentStep - 1)}>
      
      {disabledFirstStep && <Step disabled={disabledFirstStep.disabled} active={disabledFirstStep.active} completed={disabledFirstStep.completed} expanded={disabledFirstStep.expanded} key={disabledFirstStep.title} >
        <StepLabel disabled={disabledFirstStep.disabled} ><Hidden smDown>{disabledFirstStep.title}</Hidden></StepLabel>
      </Step>}
      


      {wizardState?.wizardSteps?.map(({ title, informationtext, step, backButtonTitle, nextButtonTitle,doNotShowNextButton,execSaveMethodOnNextButton }) => {
        const stepProps = {};
        const labelProps = {};
        return (

          <Step key={title + step} {...stepProps}>
            <StepLabel {...labelProps}><Hidden smDown>{title}</Hidden></StepLabel>
            {isSmallScreen && <StepContent>
              <div className={"wizard-step " + ((wizardState.isUnmounting === true) ? "unmounting" : "")} key={step}>
                {/*<h1 className={"wizard-step-title step-"+step}>{title}</h1>*/}
                {
                  informationtext !== null && <div className="wizard-step-subtitle"><p>{informationtext}</p></div>
                }
                {state?.answer?.answer.answerElement.value.map((el: AnswerElement) => (
                  (isElementIfPartOfWizardPage(step, el, wizardState)) &&
                  <HighlightFrame appState={appState} elementid={el.element.externalid} key={`${el.element.externalid}`}>
                      <AnswerElementGui appState={appState} key={`${el.element.externalid}`} el={el} jumpToWizardStep={jumpToWizardStep} />
                  </HighlightFrame>

                ))}
                <Grid container spacing={3} className="row buttonrow">
                  <Grid item xs={12}>
                    {!doNotShowNextButton && (!(state.wizardState.disabledFirstStep!=null && state.wizardState.currentStep === 2)) && (state.wizardState.currentStep > 1) && setPathToPage && <Button className="button-margin-right" startIcon={<ArrowLeftIcon />} title={backButtonTitle || 'Zurück'} onClick={() => jumpToWizardStep(wizardState?.currentStep - 1)}>{backButtonTitle || 'Zurück'}</Button>}
                    {!doNotShowNextButton && !execSaveMethodOnNextButton && (state.wizardState.currentStep < state.wizardState.numberOfSteps) && setPathToPage && <Button title={nextButtonTitle || 'Weiter'} onClick={() => jumpToWizardStep(wizardState?.currentStep + 1)}>{nextButtonTitle || 'Weiter'}</Button>}
                    {!doNotShowNextButton && execSaveMethodOnNextButton && (state.wizardState.currentStep < state.wizardState.numberOfSteps) &&   getSaveButton && getSaveButton()}
                    {!doNotShowNextButton && (state.wizardState.currentStep === state.wizardState.numberOfSteps) && getSaveButton && getSaveButton()}
                  </Grid>
                </Grid>
              </div>
            </StepContent>}
          </Step>
        );
      })}

    </Stepper>
    {!isSmallScreen
      && wizardState?.wizardSteps?.map(({ title, informationtext, step, backButtonTitle, nextButtonTitle,doNotShowNextButton,execSaveMethodOnNextButton }) => (step === wizardState.currentStep && !answerComplete) && <div className={"wizard-step " + ((wizardState.isUnmounting === true) ? "unmounting" : "")} key={step}>
        {/*<h1 className={"wizard-step-title step-"+step}>{title}</h1>*/}
        {
          informationtext !== null && <div className="wizard-step-subtitle"><p>{informationtext}</p></div>
        }
        {state?.answer?.answer.answerElement.value.map((el: AnswerElement) => (
          (isElementIfPartOfWizardPage(step, el, wizardState)) &&
          <HighlightFrame appState={appState} elementid={el.element.externalid} key={el.element.externalid}>
              <AnswerElementGui appState={appState} key={el.element.externalid} el={el} jumpToWizardStep={jumpToWizardStep} />
          </HighlightFrame>

        ))}
        <Grid container spacing={3} className="row buttonrow">
          <Grid item xs={12} sx={{
            "& img": {
              minHeight: '1rem'
            }
          }}>

            {!doNotShowNextButton && (!(state.wizardState.disabledFirstStep!=null && state.wizardState.currentStep === 2)) && (state.wizardState.currentStep > 1) && setPathToPage && <Button className="button-margin-right button-wizard-back" title={backButtonTitle || 'Zurück'} onClick={() => jumpToWizardStep(wizardState?.currentStep - 1)}><img src={zurueck} alt="Zurück" /></Button>}
            {!doNotShowNextButton && !execSaveMethodOnNextButton && (state.wizardState.currentStep < state.wizardState.numberOfSteps) && setPathToPage && <Button title={nextButtonTitle || 'Weiter'} onClick={() => jumpToWizardStep(wizardState?.currentStep + 1)}>{nextButtonTitle || 'Weiter'}</Button>}
            {!doNotShowNextButton && execSaveMethodOnNextButton && (state.wizardState.currentStep < state.wizardState.numberOfSteps) &&   getSaveButton && getSaveButton()}
            {!doNotShowNextButton && (state.wizardState.currentStep === state.wizardState.numberOfSteps) && getSaveButton && getSaveButton()}
          </Grid>
        </Grid>
      </div>)
    }
    {!isSmallScreen && answerComplete && confirmationPage}
  </>
}

export default WizardSteps;